.header {
    font-size: var(--h1-size);
    font-weight: 700;
    margin: 0 0 0.5rem 0;
}

.card {
    width: 100%;
    padding: var(--card-padding);
    margin: var(--card-margin-y) 0;
}

.projectSelector {
    margin-bottom: 1rem;
}

.select {
    background-color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    position: static;
}

.select select {
    border: none;
    min-width: 120px;
}

.select select:active {
    border: none;
}

/** ReportCard **/

.reportCardContainer {
    display: flex;
    justify-content: space-between;
}

.reportCardHealthInnerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    height: 80%;
}

.reportCardHealthRating {
    font-size: 2rem;
    font-weight: bold;
    color: var(--success);
}

.reportCardList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.reportCardList li {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
}

.reportCardList li span {
    margin: 0;
    padding: 0;
    margin-left: 0.5rem;
    font-size: var(--p-size);
}

.check,
.danger {
    margin-right: 5px;
}

.check {
    color: var(--success);
}

.danger {
    color: var(--danger);
}

.reportCardActionContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.reportCardActionText {
    max-width: 300px;
    font-size: var(--p-size);
}

.reportCardBtn {
    background-color: #f2f2f2;
}

.healthDanger {
    color: var(--danger);
}

.healthWarning {
    color: var(--warning);
}

/** ReportToggleList **/
.reportToggleList {
    width: 100%;
    margin: var(--card-margin-y) 0;
}

.bulkAction {
    background-color: #f2f2f2;
    font-size: var(--p-size);
}

.sortIcon {
    margin-left: 8px;
    transform: translateY(6px);
}

.reportToggleListHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: var(--default-border);
    padding: 1rem var(--card-padding-x);
}

.reportToggleListInnerContainer {
    padding: var(--card-padding);
}

.reportToggleListHeading {
    font-size: var(--h1-size);
    margin: 0;
    font-weight: 'bold';
}

.reportingToggleTable {
    width: 100%;
    border-spacing: 0 0.8rem;
}

.reportingToggleTable th {
    text-align: left;
}

.expired {
    color: var(--danger);
}

.active {
    color: var(--success);
}

.stale {
    color: var(--danger);
}

.reportStatus {
    display: flex;
    align-items: center;
}

.reportIcon {
    font-size: 1.5rem;
    margin-right: 5px;
}

.tableRow {
    cursor: pointer;
}
